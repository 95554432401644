import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { VpnKey, EditRounded, Delete } from "@material-ui/icons";
import React, { useEffect, useContext, useState } from "react";

import SnackbarContext from "../../contexts/Snackbar";
import requestAdapter from "../../api/request";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import ButtonRounded from "../../components/ButtonRounded";
import UpdatePixInformation from "../../components/UpdatePixInformation";
import UpdateTimeCashOutInformation from "../../components/UpdateTimeCashOutInformation";
import UpdateClientPassword from "../../components/UpdateClientPassword";
import ConfirmationDeleteMatera from "../../components/ConfirmationDeleteMatera";
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function MyClients() {
  const { setSnack } = useContext(SnackbarContext);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [code, setCode] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modalTimeShow, setModalTimeShow] = useState(false);
  const [clientToModal, setClientToModal] = useState(null);
  const [defaultConfigurations, setDefaultConfigurations] = useState(null);

  const [modalPassword, setModalPassword] = useState(false);
  const [modalDeleteMatera, setModalDelteMatera] = useState(false);
  const getData = async () => {
    try {
      const res = await requestAdapter.get(`/admin/clients`);
      if (res.status === 200) {
        if (res.data) {
          let rows = res.data.users;
          //console.log(rows);
          //rows = rows.sort((a, b) => (a.entryDate > b.entryDate ? -1 : 1));
          setDefaultConfigurations(res.data.defaultConfiguration);
          setRows(rows);
          setFilteredRows(rows);
        }
      }
    } catch (ex) {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function search() {
    let aux = rows;

    if (code) {
      aux = aux.filter(
        (a) =>
          a.taxId.indexOf(code) !== -1 ||
          a.name.indexOf(code) !== -1 ||
          a.email.indexOf(code) !== -1
      );
    }

    handleChangePage({}, 0);
    setFilteredRows(aux);
  }

  const handleConfirmChanging = () => {
    setModalShow(false);
    getData();
  };

  const handleCancelChanging = () => {
    setModalShow(false);
  };

  const handleConfirmTimeCashOutChanging = () => {
    setModalTimeShow(false);
    getData();
  };

  const handleCancelTimeCashOutChanging = () => {
    setModalTimeShow(false);
  };

  const handleOpenModal = (row) => {
    if (!row.mediatorFeePixIn) {
      row.mediatorFeePixIn = defaultConfigurations.mediatorFeePixIn;
      row.mediatorFeePixOut = defaultConfigurations.mediatorFeePixOut;
      row.mediatorFeePixBilling = defaultConfigurations.mediatorFeePixBilling;
      row.expirationTimeQRCodePix =
        defaultConfigurations.expirationTimeQRCodePix;
      row.maxTimeToReturn = defaultConfigurations.maxTimeToReturn;
    }
    setModalShow(true);
    setClientToModal(row);
  };

  const getValuePixIn = (row) => {
    if (!row.mediatorFeePixIn) return defaultConfigurations.mediatorFeePixIn;
    return row.mediatorFeePixIn;
  };
  const getValuePixOut = (row) => {
    if (!row.mediatorFeePixOut) return defaultConfigurations.mediatorFeePixOut;
    return row.mediatorFeePixOut;
  };

  const changeHour = (row) => {
    setModalTimeShow(true);
    setClientToModal(row);
  };

  async function confirmDeletion() {
    const data = clientToModal;
    setModalDelteMatera(false);
    try {
      const res = await requestAdapter.post(`/admin/clients/remove-matera`, {
        id: data.user_id,
      });

      if (res.status) {
        setSnack({ open: true, message: "Cliente removido com sucesso!" });
        getData();
      }
    } catch (ex) {
      console.log(ex);

      setSnack({
        open: true,
        message: "Ocorreu um erro, tente novamente!",
        severity: "error",
      });
    }
  }

  return (
    <div>
      <UpdatePixInformation
        show={modalShow}
        onHide={handleCancelChanging}
        confirm={handleConfirmChanging}
        client={clientToModal}
      />
      {modalDeleteMatera && (
        <ConfirmationDeleteMatera
          show={modalDeleteMatera}
          name={clientToModal.name}
          onHide={() => setModalDelteMatera(false)}
          confirm={confirmDeletion}
        />
      )}
      <UpdateTimeCashOutInformation
        show={modalTimeShow}
        onHide={handleCancelTimeCashOutChanging}
        confirm={handleConfirmTimeCashOutChanging}
        client={clientToModal}
      />
      <UpdateClientPassword
        show={modalPassword}
        onHide={() => setModalPassword(false)}
        confirm={() => setModalPassword(false)}
        client={clientToModal}
      />
      <Grid
        container
        justify="center"
        style={{ paddingTop: "25px", textAlign: "center" }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Clientes ativos na plataforma</Typography>
        </Grid>

        <Divider
          style={{
            background: "var(--color-orange-mid)",
            width: "20%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
            textAlign: "center",
          }}
        />
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ marginBottom: 10, alignItems: "center" }}
      >
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            type="text"
            onChange={(ev) => setCode(ev.target.value)}
            variant="outlined"
            label="Pesquisar por CNPJ ou Nome"
            name="code"
          />
        </Grid>

        <Grid item xs={1}>
          <ButtonRounded onClick={search} text={"Buscar"}></ButtonRounded>
        </Grid>
      </Grid>

      <Divider
        style={{
          background: "var(--color-orange-mid)",
          width: "20%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
          textAlign: "center",
        }}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>CNPJ</TableCell>
              <TableCell align="left">Nome da empresa</TableCell>
              <TableCell align="left">Chave PIX para transferência</TableCell>
              <TableCell align="left">Taxa PIX entrada</TableCell>
              <TableCell align="left">Taxa PIX saída</TableCell>
              <TableCell align="left">Hora do saque</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.taxId}
                </TableCell>

                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">
                  {!row.bankName || !row.bankAliasPix
                    ? "Sem chave cadastrada"
                    : row.bankAliasPix + " (" + row.bankName + ")"}
                </TableCell>
                <TableCell align="left">
                  R$ {Number(getValuePixIn(row)).toFixed(2)}
                </TableCell>
                <TableCell align="left">
                  R$ {Number(getValuePixOut(row)).toFixed(2)}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ cursor: "pointer", textDecorationLine: "underline" }}
                  onClick={() => changeHour(row)}
                >
                  {!row.hourToCashOut || !row.hourToCashOut
                    ? "Sem horário de saque"
                    : row.hourToCashOut + ":59"}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Editar cliente"
                    color="inherit"
                    onClick={() => handleOpenModal(row)}
                  >
                    <EditRounded />
                  </IconButton>
                  <IconButton
                    aria-label="Alterar senha"
                    color="inherit"
                    onClick={() => {
                      setClientToModal(row);
                      setModalPassword(true);
                    }}
                  >
                    <VpnKey />
                  </IconButton>
                  <IconButton
                    aria-label="Deletar cliente"
                    color="inherit"
                    onClick={() => {
                      setClientToModal(row);
                      setModalDelteMatera(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  20,
                  50,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                colSpan={3}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Linhas por página" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Linhas por página"}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default MyClients;
